import * as React from "react";

// Components
import Layout from "../../components/Layouts/Layouts";
import Seo from "../../components/Layouts/Seo";
import Banner from "../../components/Content/Banner/Index";
import LeadIn from "../../components/Content/LeadIn/Index";
import FeatureCaseStudy from "../../components/Content/FeatureArticle/Dynamic";
import FeatureApplication from "../../components/Content/FeatureApplication/Index";
import Col2 from "../../components/Content/TextMedia/Col-2";
import Col1 from "../../components/Content/TextMedia/Col-1";
import Video from "../../components/Content/Video/Index";
import TextIcon from "../../components/Content/TextIcon/Index";

// Assets
import brandLogo from "../../assets/logos/logo-bpress-stainless-steel-centered.svg";
import brandLogoLong from "../../assets/logos/logo-bpress-stainless-steel.svg";
import bannerImage from "../../assets/images/banner/banner-bpress-stainless-steel.jpg";
import leadIndicator from "../../assets/images/products/landing/leak-indicator.png";
import imageLinear from "../../assets/images/products/landing/three-point-press-diagram.jpg";
import installationVideo from "../../assets/images/products/landing/video-installation-stainless-steel.jpg";
import iconSearch from "../../assets/icons/icon-search.svg";

// data
import data from "../static-pages/landing/bpress-stainless.yaml";
import { UseBlogData } from "../../helpers/hooks/BlogQuery";

// markup
const IndexPage: React.FC = () => {
  const featured = UseBlogData();

  return (
    <Layout>
      <Seo
        title=">B< Press Stainless Steel"
        description=">B< Press Stainless Steel is a versatile press fitting system that provides a secure, permanent and leak-proof joint suitable for industrial applications. It is manufactured using high quality hygienic 316L stainless steel material."
      />
      <Banner
        type="product"
        title="Fitting System"
        summary="15-54mm"
        divider={true}
        brandMediaUrl={brandLogo}
        brandMediaWidth="400px"
        brandMediaTitle="Placeholder"
        featureMediaUrl={bannerImage}
        featureMediaTitle="Placeholder"
        removePadding={true}
      />
      <LeadIn summary=">B< Press Stainless Steel is a versatile press fitting system that provides a secure, permanent and leak-proof joint suitable for industrial applications. It is manufactured using high quality hygienic 316L stainless steel material." />
      <FeatureApplication
        sectionId="1"
        section="muted"
        heading="Applications"
        content=">B< Press Stainless Steel is suitable for use across a wide range of applications depending on medium, temperature and pressure. To find out more, download our application guide."
        divider={true}
        buttonValue="Download Application Guide"
        buttonUrl="/downloads/b-press-stainless-steel/B-Press-Stainless-Steel-Application-Guide.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
        data={data.tabs}
      />
      <LeadIn
        section="muted"
        brandHeading="Features"
        paddingSize="small"
        divider={true}
      />
      <Col2
        section="muted"
        summary="
          <h2>Leak Indicator</h2>
          <p>The unique leak indicator is a specially designed, patented O-ring. It has a reduced section in two positions that allows water or air past the sealing element of any unpressed connection, providing a visible leakage point when commissioning the system. </p>
          <p>This offers peace of mind to the installer that any unpressed fittings can be easily identified and completed before handing over the project, and gives confidence to the client that there will be no leaks during operation.</p>
          <p>Unpressed fittings are identified by pressurising the system with a pressure range of 100kPa to 500kPa for water (prior to the final pressure testing) and 2.2kPa to 300kPa for gas. When the fitting is pressed, the O-ring material compresses, filling the gaps and creating a leak-free joint.</p>
        "
        backgroundMediaUrl={leadIndicator}
        backgroundMediaTitle="Leak Indicator Diagram"
        mediaPositionClass="right"
        removePadding={true}
      />
      <Col1
        section="muted"
        heading="3-Point Press"
        summary="The >B< profile has the advantage of two mechanical presses either side of the bead and one hydraulic press crimping the O-ring. This provides a permanent and secure joint. The lead-in edge design aids installation and helps protect the O-ring from damage or displacement."
        backgroundMediaUrl={imageLinear}
        backgroundMediaTitle="Placeholder"
        buttonValue="View Range"
        buttonUrl="/products?brandTitle=>B<+Press+Stainless+Steel"
        buttonBottom={true}
        removePadding={true}
      />
      <LeadIn
        brandHeading="How To Install"
        buttonValue="Download Installation Guide"
        buttonUrl="/downloads/b-press-stainless-steel/B-Press Stainless Steel Installation Guide.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
        divider={true}
      >
        <Video
          margin={true}
          videoMediaType="youtube"
          videoMediaId="xJ7Y3vv6nWc"
          backgroundMediaUrl={installationVideo}
          backgroundMediaTitle="Installation"
          backgroundMediaWidth="800px"
        />
      </LeadIn>
      <LeadIn
        section="default"
        subHeading="Why choose"
        brandMediaUrl={brandLogoLong}
        brandMediaWidth="655px"
        brandMediaTitle="Placeholder"
        buttonValue="Download Technical Guide"
        buttonUrl="/downloads/b-press-stainless-steel/B-Press-Stainless-Steel-Technical-Guide.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
        divider={true}
        paddingSize="small"
      >
        <TextIcon data={data.facts} />
      </LeadIn>
      <LeadIn
        section="muted"
        subHeading="25 year"
        brandHeading="Warranty"
        divider={true}
        summary="A 25 year warranty covers against faults caused by defective manufacture of the fittings. It does not cover faults arising from incorrect installation."
        content="All >B< Press Stainless Steel fittings must be installed by a licensed plumber."
        buttonValue="Download Warranty"
        buttonUrl="/downloads/b-press-stainless-steel/B-Press Stainless Steel Fittings Warranty.pdf"
        buttonTarget="_blank"
        buttonRel="noopener noreferrer"
      />
      <FeatureCaseStudy
        paddingSize="large"
        data={featured.bpressstainlesssteel}
      />
      <LeadIn
        section="primary"
        brandMediaUrl={iconSearch}
        brandMediaTitle="Download"
        brandMediaWidth="69px"
        summary="Need help finding the right system for the job?"
        paddingSize="small"
        buttonValue="Launch Product Selector"
        buttonUrl="#js-product-selector-app"
        buttonModal={true}
      />
    </Layout>
  );
};

export default IndexPage;
